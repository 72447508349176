<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <!-- Brand logo-->
        <div class="i-brand-logo">
          <light-vertical-ostrail-logo />
        </div>
        <!-- /Brand logo-->

        <!-- Forgot password-->
        <div class="mt-1">
          <b-card-title class="mb-1">
            {{ $t('loginMessages.forgotPassword') }} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('loginMessages.enterEmail') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                :label="$t('message.tableHeader.email')"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@ostrail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="dark"
                block
                pill
                :disabled="showSpinnerFP"
              >
                <b-spinner
                  v-if="showSpinnerFP"
                  small
                />
                <span class="ml-spinner">{{ $t('loginMessages.sendResetLink') }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <i class="icon-0-icons-dark-step-back" /> {{ $t('loginMessages.backToLogin') }}
            </b-link>
          </p>
          <br>
        </div>
      <!-- /Forgot password-->
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable global-require */
import store from '@/store/index'
import { BCard, BLink, BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import authService from '@/services/authService'
import LightVerticalOstrailLogo from '@/views/partials/LightVerticalOstrailLogo.vue'

export default {
  components: {
    BCard,
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    LightVerticalOstrailLogo,
  },

  data() {
    return {
      showSpinnerFP: false,
      userEmail: '',
      required, // validation
      email, // validation
    }
  },

  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            email: this.userEmail,
            current_locale: this.$i18n.locale,
          }

          this.showSpinnerFP = true
          authService.setValidationEmail(params).then(({ data }) => {
            this.showSpinnerFP = false
            this.responseSuccess(data.message[0])
            setTimeout(() => { this.$router.replace('/login') }, 1500)
          }).catch(error => {
            this.showSpinnerFP = false
            this.responseCatch(error)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
